const recalculate = {
	id: 'recalculateAction',
	selectionType: 'single',
	label: 'action.recalculate',
	functionality: 'WRITE_AGC',
	checkAvailability: function(/*registries*/) {
		return true;
	},
	runAction: function(action, model, registries) {
		const row = registries[0];
		const objectPk = {};
		// Comprobar PK (En este caso es compuesta)
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-recalculate-' + model.name + '-show', row);
	}
};

export default {
	gridActions: [recalculate],
	formActions: [recalculate]
};
