<template>
	<div>
		<pui-modal-dialog-form
			:overflow="false"
			:titleText="$t('agc.recalculateTitle')"
			:modelName="modelName"
			:dialogName="dialogNameRecalculate"
			v-model="modalData"
			:widthDialog="500"
			:onOk="recalculate"
			:showDialogProp="getShowDialogRecalculate"
		>
			<template slot="message">
				<v-layout class="justify-center">
					<v-flex xs12>
						<label>{{ $t('agc.askRecalculate') }}</label>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'agcmodals',
	data() {
		return {
			model: 'agc',
			dialogNameRecalculate: 'recalculate',
			showDialogRecalculate: true,
			modalData: {}
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		},
		modelColumnDefs: {
			type: Object
		}
	},
	mounted() {
		this.$puiEvents.$on('pui-modalDialogForm-recalculate-agc-show', () => {
			this.showDialogRecalculate = true;
		});
	},
	computed: {
		getShowDialogRecalculate() {
			return this.showDialogRecalculate;
		}
	},
	methods: {
		recalculate(data) {
			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('action.recalculate');
			const urlController = '/agc/recalculate';
			const body = {
				id: data.id
			};

			this.$puiRequests.getRequest(
				urlController,
				body,
				() => {
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
					this.$puiEvents.$emit(`refreshForm-action-running-ended-${this.modelName}`);
					var message = this.$puiI18n.t('puiaction.notifySuccess');
					this.$puiNotify.success(message, title);
					this.showDialogRecalculate = false;
				},
				(e) => {
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
					var message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiNotify.error(message, title);
					this.showDialogRecalculate = false;
				}
			);
		}
	},
	destroyed() {
		this.$puiEvents.$off(`pui-modalDialogForm-${this.dialogNameRecalculate}-agc-show`);
	}
};
</script>

